import Metric from './Metric';

/**
 * Analytics Kpis
 * @class
 * @constructor
 * @public
 */
export default class AnalyticsKpis {
  /**
   * @typedef {Object} AnalyticsKpis
   * @property @param {Metric} yesterday
   * @property @param {Metric} mtd
   */
  constructor(yesterday = new Metric(), mtd = new Metric()) {
    this.yesterday = yesterday;
    this.mtd = mtd;
  }

  static build({ yesterday, mtd }) {
    const yesterdayMetric = Metric.build(yesterday);
    const mtdMetric = Metric.build(mtd);
    return new AnalyticsKpis(yesterdayMetric, mtdMetric);
  }
}
