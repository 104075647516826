import { dashboardSSP } from '..';
import { getAnalyticsDeal } from './getAnalyticsDeal';
import { getAnalyticsDealChart } from './getAnalyticsDealChart';
import { getAnalyticsDealTable } from './getAnalyticsDealTable';
import { getAnalyticsDealTotals } from './getAnalyticsDealTotal';
import { getAnalyticsDealOttTotals } from './getAnalyticsDealOttTotal';
import { getAnalyticsMarketTotals } from './getAnalyticsMarketTotal';
import { getAnalyticsDealKpis } from './getAnalyticsDealKpis';
import { getAnalyticsDealOttKpis } from './getAnalyticsDealOttKpis';
import { getAnalyticsMarket } from './getAnalyticsMarket';
import { getAnalyticsMarketChart } from './getAnalyticsMarketChart';
import { getAnalyticsMarketTable } from './getAnalyticsMarketTable';
import { getAnalyticsMarketKpis } from './getAnalyticsMarketKpis';
import { getMarketChartV2 } from './v2/getMarketChartV2';
import { getDealChartV2 } from './v2/getDealChartV2';
import { getDealOttChartV2 } from './v2/getDealOttChartV2';
import { getTableV2 } from './v2/getTableV2';
import { getAnalyticsMarketsTotals } from './getAnalyticsMarketsTotals';
import { getAnalyticsMarketsKpis } from './getAnalyticsMarketsKpis';
import { getAnalyticsMarketsChart } from './getAnalyticsMarketsChart';
import createAnalyticsReport from './report/createAnalyticsReport';
import getAnalyticsReport from './report/getAnalyticsReport';
import getAnalyticsReportStatus from './report/getAnalyticsReportStatus';
import getIngestionJobNotifications from './getIngestionJobNotifications';
import { getAnalyticsSkinTotals } from './getAnalyticsSkinTotals';
import { getAnalyticsSkinKpis } from './getAnalyticsSkinKpis';
import { getAnalyticsSkinChart } from './getAnalyticsSkinChart';

export const api = dashboardSSP;

export {
  getAnalyticsDeal,
  getAnalyticsDealChart,
  getAnalyticsDealTable,
  getAnalyticsDealTotals,
  getAnalyticsDealKpis,
  getAnalyticsMarket,
  getAnalyticsMarketChart,
  getAnalyticsMarketTable,
  getAnalyticsMarketKpis,
  getAnalyticsMarketTotals,
  createAnalyticsReport,
  getAnalyticsReport,
  getAnalyticsReportStatus,
  getIngestionJobNotifications,
  getMarketChartV2,
  getDealChartV2,
  getAnalyticsDealOttKpis,
  getAnalyticsDealOttTotals,
  getDealOttChartV2,
  getTableV2,
  getAnalyticsMarketsTotals,
  getAnalyticsMarketsKpis,
  getAnalyticsMarketsChart,
  getAnalyticsSkinTotals,
  getAnalyticsSkinChart,
  getAnalyticsSkinKpis,
};
