export const PLATFORM_PROVIDERS = {
  APPNEXUS: 'appnexus',
  CAMPAIGN_MANAGER: 'campaign-manager',
  ZEMANTA: 'zemanta',
  ADFORM: 'adform',
  SMART: 'smart-adserver',
  DV360: 'dv360',
  DIV360: 'div360',
  TABOOLA: 'taboola',
};
