import { api } from '..';
import { ANALYTICS_RESOURCE, CLIENT_RESOURCE, REPORT_RESOURCE } from '@/services/keys';

/**
 * @param {string} clientId
 * @param {string} reportId
 * @returns {Promise<ReportStatus>}
 */
export default async function (clientId, reportId) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: clientId,
    [ANALYTICS_RESOURCE]: null,
    [REPORT_RESOURCE]: reportId,
  });
  const { data } = await api.get(partialUrl);
  return data?.data?.attributes?.status || null;
}
