import { api } from '../index';
import { ANALYTICS_RESOURCE, CLIENT_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import getDateUTC from '@/utils/dateTime/getDateUTC';

/**
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @return {Promise<{data:Analytic[],meta:EndpointMeta}>}
 */
export async function getTableV2(clientId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: clientId,
    [ANALYTICS_RESOURCE]: undefined,
  });

  params.removeFilter('compare_range_start');
  params.removeFilter('compare_range_end');

  params.addFilter('range_start', getDateUTC(params.removeFilter('range_start')));
  params.addFilter('range_end', getDateUTC(params.removeFilter('range_end')));

  const { data } = await api.get(partialUrl, params.build(), { preventCancel: true });
  data.data.forEach(row => (row.fillRate = row.fillRate ? Number(row.fillRate) : null));
  return data;
}
