const ITEMS_PER_PAGE_DEFAULT = 25;

export default class DetailPagination {
  constructor(itemsPerPage = ITEMS_PER_PAGE_DEFAULT) {
    this.itemsPerPage = itemsPerPage;
    this.items = new Map();
    this.loadingItems = [];
  }

  addItem(id, maxPage) {
    if (this.items.has(id)) return;

    this.items.set(id, {
      currentPage: 1,
      maxPage,
    });
  }

  getItemsPerPage() {
    return this.itemsPerPage;
  }

  getCurrentPage(id) {
    if (!this.items.has(id)) return 1;

    return this.items.get(id).currentPage;
  }

  isLastPage(id) {
    if (!this.items.has(id)) return true;

    const item = this.items.get(id);
    return item.currentPage >= item.maxPage;
  }

  nextPage(id) {
    if (this.isLastPage(id)) return 0;

    return ++this.items.get(id).currentPage;
  }

  isLoading(id) {
    return this.loadingItems.includes(id);
  }

  setLoading(id, loading) {
    if (loading) this.loadingItems.push(id);
    else this.loadingItems = this.loadingItems.filter(idItem => idItem !== id);
  }

  setError(id) {
    if (!this.items.has(id)) return null;
    this.items.get(id).hasError = true;
  }

  getError(id) {
    return this.items.get(id).hasError;
  }

  endLoadingAll() {
    this.loadingItems = [];
  }

  getMaxPage(id) {
    if (!this.items.has(id)) return 1;

    return this.items.get(id).maxPage;
  }

  setMaxPage(id, maxPage) {
    if (!this.items.has(id)) return;

    this.items.get(id).maxPage = maxPage;
  }

  reset() {
    this.items.clear();
  }
}
