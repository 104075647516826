<template>
  <span
    v-if="platform"
    class="my-1 px-2 py-1 leading-tight align-middle inline-flex items-center rounded-full"
    :class="bgColor"
  >
    <svg fill="currentColor" viewBox="0 0 8 8" class="h-2 w-2" :class="textColor">
      <circle cx="4" cy="4" r="3" />
    </svg>
    <span class="ml-2 text-xs font-bold whitespace-no-wrap" :class="textColor">
      {{ platform.name }}
    </span>
  </span>
</template>

<script>
import { PLATFORM_PROVIDERS } from '@/entities/ThirdParty/PlatformProviders';

export default {
  name: 'PlatformPill',
  props: {
    platform: {
      type: Object,
      default: () => null,
    },
    allowNavigation: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {
    canNavigate() {
      return this.allowNavigation && this.platformId;
    },
    cursorState() {
      return this.canNavigate ? 'cursor-pointer' : 'cursor-default';
    },
    platformId() {
      return this.platform?.id || this.platform?.platformId;
    },
    bgColor() {
      return `bg-${this.colorByProvider}-100`;
    },
    textColor() {
      return `text-${this.colorByProvider}-900`;
    },
    colorByProvider() {
      const provider = this.platform?.provider || this.platform?.platformProvider?.name || this.platform?.name;
      switch (provider?.toLowerCase()) {
        case PLATFORM_PROVIDERS.CAMPAIGN_MANAGER:
          return 'teal';
        case PLATFORM_PROVIDERS.ADFORM:
          return 'yellow';
        case PLATFORM_PROVIDERS.ZEMANTA:
          return 'orange';
        case PLATFORM_PROVIDERS.SMART:
          return 'blue';
        case PLATFORM_PROVIDERS.DV360:
          return 'red';
        case PLATFORM_PROVIDERS.DIV360:
          return 'green';
        case PLATFORM_PROVIDERS.TABOOLA:
          return 'indigo';
        case PLATFORM_PROVIDERS.APPNEXUS:
          return 'purple';
        default:
          return 'gray';
      }
    },
  },
  methods: {
    handleClick(event) {
      if (!this.canNavigate) {
        event.stopPropagation();
        event.preventDefault();
      }
    },
  },
};
</script>
