import { isValidStringDate } from '@/utils/validation/isValidStringDate';
import Column from '@/model/shared/Column';
import { BREAKDOWN_VALUES } from '@/model/modules/dashboardSSP/analytics/BreakdownValues';
import { dateList as defaultDateList } from '@/utils/dateTime/dateList.js';

export const CUSTOM_OPTION = 'Custom';

export const COMMON_ID_VIEW = 'deals_markets_dealsOtt_openMarket';

export const COLOR_OPTIONS = {
  DEFAULT: 0,
  GREEN: 1,
  RED: 2,
};

export const dateComparator = (filterLocalDateAtMidnight, dateAsString) => {
  if (dateAsString == null || !isValidStringDate(dateAsString)) {
    return -1;
  }

  const dateParts = dateAsString.split('-');
  const year = Number(dateParts[0]);
  const month = Number(dateParts[1]) - 1;
  const day = Number(dateParts[2]);
  const cellDate = new Date(year, month, day);

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }
  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};

export const textFilterValueGetter = params => {
  const colId = params.column.getColId();
  const value = params.data[colId];
  const valueIsNA = value === 'N/A';
  const parentData = params?.node?.parent?.aggData;
  const parentHasData = !!parentData && parentData[colId];

  if (valueIsNA && parentHasData) {
    return parentData[colId];
  }

  return params.data[colId];
};

export const columnTypes = {
  dateColumn: {
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: dateComparator,
    },
  },
  textColumn: {
    filter: 'agTextColumnFilter',
    filterValueGetter: textFilterValueGetter,
  },
  numberColumn: {
    filter: 'agNumberColumnFilter',
  },
};

export const dateList = defaultDateList;

export const defaultColDef = {
  sortable: true,
  unSortIcon: true,
  filter: true,
  resizable: true,
  floatingFilter: true,
  minWidth: 80,
  width: 150,
  cellRenderer: 'AnalyticsCellOrchestrator',
  suppressMenu: true,
};

export const rowClassRules = {
  'deal-starting': params => {
    return +params.data?.color === COLOR_OPTIONS.GREEN || +params.node.aggData?.color === COLOR_OPTIONS.GREEN;
  },
  'deal-ending': params => {
    return +params.data?.color === COLOR_OPTIONS.RED || +params.node.aggData?.color === COLOR_OPTIONS.RED;
  },
};

export const BREAKDOWN_COLUMNS = Object.freeze({
  NAME: new Column('NAME', 'deal.name')
    .setClass('font-bold truncate')
    .setBreakdowns([BREAKDOWN_VALUES.deal])
    .asStickyColumn(),
  DATE: new Column('DATE', 'analyticsDeal.date').setBreakdowns([BREAKDOWN_VALUES.date]).asStickyColumn(),
  COUNTRY: new Column('COUNTRY', 'analyticsDeal.country').setBreakdowns([BREAKDOWN_VALUES.country]).asStickyColumn(),
  PLATFORM: new Column('PLATFORM', 'platform.name').setBreakdowns([BREAKDOWN_VALUES.platform]).asStickyColumn(),
});

export const COLUMNS = Object.freeze({
  OFFICE: new Column('OFFICE', 'office'),
  NET_REVENUE_EUR: new Column('NET REVENUE EUR', 'netRevenue').toRight().setCurrency('EUR'),
  NET_REVENUE_USD: new Column('NET REVENUE USD', 'netRevenueUsd').toRight().setCurrency('USD'),
  IMPRESSIONS: new Column('IMPRESSIONS', 'impressions').toRight(),
  REQUESTS: new Column('REQUESTS', 'requests').toRight(),
  CLICKS: new Column('CLICKS', 'clicks').toRight(),
  NET_ECPM_EUR: new Column('NET CPM EUR', 'netECPM').toRight().setCurrency('EUR'),
  NET_ECPM_USD: new Column('NET CPM USD', 'netECPMUsd').toRight().setCurrency('USD'),
  CTR: new Column('CTR', 'ctr').toRight(),
  OPORTUNITY_NAME: new Column('OPPORTUNITY NAME', 'oppName'),
  ACCOUNT_NAME: new Column('ACCOUNT NAME', 'accountName'),
  PLATFORM_ID: new Column('PLATFORM ID', 'oppId'),
  START_DATE: new Column('START DATE', 'startDate'),
  END_DATE: new Column('END DATE', 'endDate'),
  VTR: new Column('VTR', 'vtr').toRight(),
  VIEWS: new Column('VIEWS', 'views').toRight(),
  VIEWABLE_IMPRESSIONS: new Column('VIEWABLE IMPRESSIONS', 'viewableImpressions').toRight(),
  VIEWABILITY: new Column('VIEWABILITY', 'viewability').toRight(),
  FILL_RATE: new Column('FILL RATE', 'fillRate').toRight(),
  DEAL_ID: new Column('DEAL ID', 'dealId'),
  PLATFORM_NAME: new Column('PLATFORM', 'platformName').setBreakdowns([BREAKDOWN_VALUES.deal]),
  COLOR: new Column('COLOR', 'color').setVisible(false),
});

export const defaultColumns = [
  COLUMNS.OFFICE,
  COLUMNS.DEAL_ID,
  COLUMNS.PLATFORM_NAME,
  COLUMNS.OPORTUNITY_NAME,
  COLUMNS.ACCOUNT_NAME,
  COLUMNS.PLATFORM_ID,
  COLUMNS.START_DATE,
  COLUMNS.END_DATE,
  COLUMNS.REQUESTS,
  COLUMNS.IMPRESSIONS,
  COLUMNS.FILL_RATE,
  COLUMNS.VIEWABLE_IMPRESSIONS,
  COLUMNS.VIEWABILITY,
  COLUMNS.VIEWS,
  COLUMNS.VTR,
  COLUMNS.CLICKS,
  COLUMNS.CTR,
  COLUMNS.NET_REVENUE_EUR,
  COLUMNS.NET_ECPM_EUR,
  COLUMNS.NET_REVENUE_USD,
  COLUMNS.NET_ECPM_USD,
  COLUMNS.COLOR,
];

export const breakdown = {
  name: 'Group by',
  value: 'groupBy',
  items: [
    {
      name: 'Country',
      value: BREAKDOWN_VALUES.country,
      items: {
        name: 'By Country',
        value: 'byCountry',
        items: [
          { name: 'Date', value: BREAKDOWN_VALUES.date },
          { name: 'Deal', value: BREAKDOWN_VALUES.deal },
          { name: 'Platform', value: BREAKDOWN_VALUES.platform },
        ],
      },
    },
    {
      name: 'Date',
      value: BREAKDOWN_VALUES.date,
      items: {
        name: 'By Date',
        value: 'byDate',
        items: [
          { name: 'Country', value: BREAKDOWN_VALUES.country },
          { name: 'Deal', value: BREAKDOWN_VALUES.deal },
          { name: 'Platform', value: BREAKDOWN_VALUES.platform },
        ],
      },
    },
    {
      name: 'Deal',
      value: BREAKDOWN_VALUES.deal,
      items: {
        name: 'By Deal',
        value: 'byDeal',
        items: [
          { name: 'Country', value: BREAKDOWN_VALUES.country },
          { name: 'Date', value: BREAKDOWN_VALUES.date },
          { name: 'Platform', value: BREAKDOWN_VALUES.platform },
        ],
      },
    },
    {
      name: 'Platform',
      value: BREAKDOWN_VALUES.platform,
      items: {
        name: 'By Platform',
        value: 'byPlatform',
        items: [
          { name: 'Country', value: BREAKDOWN_VALUES.country },
          { name: 'Date', value: BREAKDOWN_VALUES.date },
          { name: 'Deal', value: BREAKDOWN_VALUES.deal },
        ],
      },
    },
  ],
};

export const transformColumnsForAgGrid = column => {
  switch (column.value) {
    case BREAKDOWN_COLUMNS.COUNTRY.value:
      column.pinned = 'left';
      column.minWidth = 120;
      column.width = 120;
      column.type = 'textColumn';
      column.field = 'country';
      column.suppressSizeToFit = true;
      column.suppressAutoSize = true;
      break;
    case BREAKDOWN_COLUMNS.DATE.value:
      column.pinned = 'left';
      column.minWidth = 100;
      column.width = 100;
      column.type = 'dateColumn';
      column.field = 'date';
      column.suppressSizeToFit = true;
      column.suppressAutoSize = true;
      break;
    case BREAKDOWN_COLUMNS.NAME.value:
      column.pinned = 'left';
      column.minWidth = 200;
      column.width = 200;
      column.type = 'textColumn';
      column.field = 'dealName';
      column.suppressSizeToFit = true;
      column.suppressAutoSize = true;
      break;
    case BREAKDOWN_COLUMNS.PLATFORM.value:
      column.pinned = 'left';
      column.minWidth = 200;
      column.width = 200;
      column.type = 'textColumn';
      column.field = 'platformName';
      column.suppressSizeToFit = true;
      column.suppressAutoSize = true;
      break;
    case COLUMNS.OPORTUNITY_NAME.value:
    case COLUMNS.ACCOUNT_NAME.value:
    case COLUMNS.PLATFORM_ID.value:
    case COLUMNS.PLATFORM_NAME.value:
    case COLUMNS.OFFICE.value:
    case COLUMNS.DEAL_ID.value:
      column.type = 'textColumn';
      break;
    case COLUMNS.START_DATE.value:
    case COLUMNS.END_DATE.value:
      column.filter = false;
      column.type = 'dateColumn';
      break;
    case COLUMNS.VIEWABILITY.value:
    case COLUMNS.VTR.value:
    case COLUMNS.CTR.value:
    case COLUMNS.NET_ECPM_EUR.value:
    case COLUMNS.NET_ECPM_USD.value:
    case COLUMNS.NET_REVENUE_EUR.value:
    case COLUMNS.NET_REVENUE_USD.value:
      column.filter = false;
      column.comparator = (valueA, valueB) => {
        const valA = isNaN(valueA) ? -1 : valueA;
        const valB = isNaN(valueB) ? -1 : valueB;

        if (valA === valB) return 0;

        return valA > valB ? 1 : -1;
      };
      column.type = 'numberColumn';
      column.filterParams = {
        allowedCharPattern: '\\d\\-\\,\\.',
        numberParser: text => {
          if (!text) return;
          return parseFloat(text.replace(',', '.'));
        },
      };
      break;
    default:
      column.filter = false;
      column.comparator = (valueA, valueB) => {
        const valA = isNaN(valueA) ? -1 : valueA;
        const valB = isNaN(valueB) ? -1 : valueB;

        if (valA === valB) return 0;

        return valA > valB ? 1 : -1;
      };
      column.type = 'numberColumn';
      break;
  }
  return { headerName: `${column.visibleTitle()}`, field: column.field || column.value, ...column };
};
