import { api } from '..';
import { ANALYTICS_RESOURCE, REPORT_RESOURCE, CLIENT_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

/**
 * @param {string} clientId
 * @param {object} config
 * @returns {Promise<{data:string}>}
 */
export default async function (clientId, config) {
  const { format, type, ...params } = config;

  const payload = {
    format,
    payload: { ...params },
    [`filters[platformDeal.type]`]: type,
  };
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: clientId,
    [ANALYTICS_RESOURCE]: undefined,
    [REPORT_RESOURCE]: undefined,
  });
  const { data } = await api.create(partialUrl, payload);
  return new ResponseBuilder(data.analyticReport).build();
}
