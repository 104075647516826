import { api } from '.';
import { ANALYTICS_RESOURCE, CLIENT_RESOURCE, TOTALS_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import Metric from '@/entities/dashboardSSP/Metric';
import getDateUTC from '@/utils/dateTime/getDateUTC';

/**
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @returns {Promise<{Metric}>}
 */
export async function getAnalyticsTotals(clientId, params = new QueryParamsBuilder(), key) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: clientId,
    [ANALYTICS_RESOURCE]: null,
    [TOTALS_RESOURCE]: null,
  });

  params.removeFilter('compare_range_start');
  params.removeFilter('compare_range_end');

  params.addFilter('range_start', getDateUTC(params.removeFilter('range_start')));
  params.addFilter('range_end', getDateUTC(params.removeFilter('range_end')));
  params.addFilter('breakdown', 'Total');

  const { data } = await api.get(partialUrl, params.buildWithoutPage(), { preventKey: key });
  return Metric.build(data.metrics);
}
