import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { getAnalyticsTotals } from './getAnalyticsTotals';

const PREVENT_KEY = 'dealOttTotal';
/**
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @returns {Promise<{Metric}>}
 */
export async function getAnalyticsDealOttTotals(clientId, params = new QueryParamsBuilder()) {
  return await getAnalyticsTotals(clientId, params, PREVENT_KEY);
}
