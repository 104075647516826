var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isProduct
        ? _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selected,
                  expression: "selected",
                },
              ],
              staticClass: "bg-gray-50 text-gray-900 text-sm w-full p-1",
              attrs: { id: `action-product-${_vm.product.id}` },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selected = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.changeStatus,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "-", disabled: "" } }, [
                _vm._v("-"),
              ]),
              _vm._l(_vm.options, function (option) {
                return _c(
                  "option",
                  { key: option.id, domProps: { value: option.id } },
                  [_vm._v(" " + _vm._s(option.value) + " ")]
                )
              }),
            ],
            2
          )
        : _c("text-cell"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }