// import { DEAL_STATUS } from '@/model/modules/dashboardSSP/deal/DealStatus';
// import { getDealTypeList } from '@/services/modules/dashboardSSP/deal/getDealTypeList';
// import { getSalesforceUsers, getSalesforceUserById } from '@/services/modules/Core/salesforce';
// import { getSellersUsers } from '@/services/modules/dashboardSSP/user/getSellersUsers';
// import { getUserById } from '@/services/modules/Core/user/getUserById';
// import { ROLES } from '@/model/shared/roles';
// import DealType from '@/entities/dashboardSSP/DealType';
// import LINK_STATUS_LIST from '@/model/modules/dashboardSSP/platformDeal/LinkStatus';
import { getCountryByIso } from '@/services/modules/Core/country';
import Filter from '@/model/shared/Filter';
import { getPlatformById, getPlatforms } from '@/services/modules/dashboardSSP/platform';

export const FILTERS_KEYS = {
  // ANALYTICS_SELLER_ID: 'ANALYTICS_SELLER_ID',
  COUNTRY_NAME: 'COUNTRY_NAME',
  COUNTRY: 'COUNTRY',
  DEAL_NAME: 'DEAL_NAME',
  // DEAL_TYPE: 'DEAL_TYPE',
  LINK_STATUS: 'LINK_STATUS',
  PLATFORM_DEAL_NAME: 'PLATFORM_DEAL_NAME',
  PLATFORM_ID: 'PLATFORM_ID',
  SALESFORCE_SELLER_ID: 'SALESFORCE_SELLER_ID',
  STATUS: 'STATUS',
  TRANSACTION_ID: 'TRANSACTION_ID',
};

export default {
  // TODO: hidden for now, maybe dont need it
  // ANALYTICS_SELLER_ID: new Filter(FILTERS_KEYS.ANALYTICS_SELLER_ID, 'Seller', 'deal.sellerId')
  //   .getItemBy(getUserById)
  //   .setOptions(clientId => params => getSellersUsers(params, clientId))
  //   .setAllowedRoles(ROLES.ADMIN, ROLES.AD_OPS, ROLES.CLIENT),
  // DEAL_NAME: new Filter(FILTERS_KEYS.DEAL_NAME, 'Name', 'deal.name'),
  // DEAL_TYPE: new Filter(FILTERS_KEYS.DEAL_TYPE, 'Type', 'deal.type', 'name', 'name')
  //   .setOptions(getDealTypeList)
  //   // this is because back dont have endpoint to get deal type by name
  //   .getItemBy(async name => new DealType(Date.now(), name)),
  // SALESFORCE_SELLER_ID: new Filter(FILTERS_KEYS.SALESFORCE_SELLER_ID, 'Seller', 'deal.sellerId')
  //   .setOptions(getSalesforceUsers)
  //   .getItemBy(getSalesforceUserById)
  //   .setAllowedRoles(ROLES.ADMIN, ROLES.AD_OPS, ROLES.CLIENT),
  // TRANSACTION_ID: new Filter(FILTERS_KEYS.TRANSACTION_ID, 'Transaction ID', 'deal.idOpportunity'),
  // STATUS: new Filter(FILTERS_KEYS.STATUS, 'Status', 'status').setOptions(DEAL_STATUS),
  // LINK_STATUS: new Filter(FILTERS_KEYS.LINK_STATUS, 'Link Status', 'link.status').setOptions(LINK_STATUS_LIST),
  NAME: new Filter(FILTERS_KEYS.NAME, 'Name', 'name'),

  PLATFORM_DEAL_NAME: new Filter(FILTERS_KEYS.DEAL_NAME, 'Name', 'platformDeal.name'),
  COUNTRY_NAME: new Filter(FILTERS_KEYS.COUNTRY_NAME, 'Country', 'country.name').asMultiple(),
  DEAL_COUNTRY_NAME: new Filter(FILTERS_KEYS.COUNTRY_NAME, 'Country', 'analyticsDeal.country').asMultiple(),
  COUNTRY: new Filter(FILTERS_KEYS.COUNTRY, 'Country', 'countryIso').getItemBy(getCountryByIso).asMultiple(),

  PLATFORM_ID: new Filter(FILTERS_KEYS.PLATFORM_ID, 'Platform', 'platform.id')
    .setOptions(clientId => params => getPlatforms(params, clientId))
    .getItemBy(getPlatformById)
    .asMultiple(),
};
