var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "w-full h-full flex items-center justify-center",
      class: _vm.column,
    },
    [
      _vm.isTextCell
        ? _c("text-cell", { attrs: { value: _vm.value } })
        : _vm.isTagCell
        ? _c("text-cell", { attrs: { value: _vm.value.join(", ") } })
        : _vm.isPillCell
        ? _c("platform-pill", {
            attrs: { platform: { name: _vm.value }, "allow-navigation": false },
          })
        : _vm.isNumericCell
        ? _c("numeric-cell", { attrs: { value: _vm.value } })
        : _vm.isPercentageCell
        ? _c("percentage-cell", { attrs: { value: _vm.value } })
        : _vm.isCurrencyCell
        ? _c("currency-cell", {
            attrs: { value: _vm.value.value, currency: _vm.value.currency },
          })
        : _vm.isCurrencyAndEurCell
        ? _c("currency-cell", { attrs: { value: _vm.value, currency: "EUR" } })
        : _vm.isCurrencyAndUsdCell
        ? _c("currency-cell", { attrs: { value: _vm.value, currency: "USD" } })
        : _vm.isFillRate
        ? _c("percentage-cell", {
            attrs: { value: _vm.value, "maximum-fraction-digits": 4 },
          })
        : _vm.isDateCell
        ? _c("date-cell", { attrs: { value: _vm.value } })
        : _vm.isSelectCampaignManagerActionCell
        ? _c("campaign-manager-status-action-select", {
            attrs: { data: _vm.rowData },
          })
        : _vm.isActionCell
        ? _c("campaign-manager-actions", { attrs: { data: _vm.rowData } })
        : _c("span", { staticClass: "bg-red-200" }, [
            _vm._v("--" + _vm._s(_vm.value)),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }