import { api } from '.';
import { ANALYTICS_RESOURCE, CLIENT_RESOURCE, DASHBOARD_KPIS } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import AnalyticsKpis from '@/entities/dashboardSSP/AnalyticsKpis';

const PREVENT_KEY = 'dealKpi';
/**
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<{data: import('@/entities/dashboardSSP/Analytics').default[], meta:import('@/model/shared/ResponseBuilder').EndpointMeta}>}
 */
export async function getAnalyticsDealKpis(clientId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: clientId,
    [ANALYTICS_RESOURCE]: null,
    [DASHBOARD_KPIS]: null,
  });
  params.removeFilter('range_start');
  params.removeFilter('range_end');

  const { data } = await api.get(partialUrl, params.buildWithoutPage(), { preventKey: PREVENT_KEY });
  return AnalyticsKpis.build(data.kpis);
}
