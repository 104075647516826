import { api } from '.';
import { ANALYTICS_RESOURCE, CLIENT_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import getDateUTC from '@/utils/dateTime/getDateUTC';

const PREVENT_KEY = 'skinChart';
/**
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @return {Promise<{data:AnalyticEconomicsKpis[],meta:EndpointMeta}>}
 */
export async function getAnalyticsSkinChart(clientId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: clientId,
    [ANALYTICS_RESOURCE]: undefined,
  });

  params.removeFilter('compare_range_start');
  params.removeFilter('compare_range_end');

  params.addFilter('range_start', getDateUTC(params.removeFilter('range_start')));
  params.addFilter('range_end', getDateUTC(params.removeFilter('range_end')));

  const { data } = await api.get(partialUrl, params.build(), { preventKey: PREVENT_KEY });
  return data;
}
