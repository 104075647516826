<template>
  <div class="w-full h-full flex items-center justify-center" :class="column">
    <text-cell v-if="isTextCell" :value="value" />
    <text-cell v-else-if="isTagCell" :value="value.join(', ')" />
    <platform-pill v-else-if="isPillCell" :platform="{ name: value }" :allow-navigation="false" />
    <numeric-cell v-else-if="isNumericCell" :value="value" />
    <percentage-cell v-else-if="isPercentageCell" :value="value" />
    <currency-cell v-else-if="isCurrencyCell" :value="value.value" :currency="value.currency" />
    <currency-cell v-else-if="isCurrencyAndEurCell" :value="value" currency="EUR" />
    <currency-cell v-else-if="isCurrencyAndUsdCell" :value="value" currency="USD" />
    <percentage-cell v-else-if="isFillRate" :value="value" :maximum-fraction-digits="4" />
    <date-cell v-else-if="isDateCell" :value="value" />
    <campaign-manager-status-action-select v-else-if="isSelectCampaignManagerActionCell" :data="rowData" />
    <campaign-manager-actions v-else-if="isActionCell" :data="rowData" />
    <span v-else class="bg-red-200">--{{ value }}</span>
  </div>
</template>

<script>
import NumericCell from '@/components/atoms/AnalyticsTable/NumericCell';
import TextCell from '@/components/atoms/AnalyticsTable/TextCell';
import { COLUMNS as openMarketColumns } from '@/views/private/modules/dashboardSSP/analytics/config';
import PercentageCell from '@/components/atoms/AnalyticsTable/PercentageCell';
import CurrencyCell from '@/components/atoms/AnalyticsTable/CurrencyCell';
import DateCell from '@/components/atoms/AnalyticsTable/DateCell';
import PlatformPill from '@/components/atoms/PlatformsList/PlatformPill';
import CampaignManagerActions from '@/components/molecules/modules/dashboardSSP/PGRCampaignManagerActions.vue';
import CampaignManagerStatusActionSelect from '@/components/molecules/modules/dashboardSSP/PGRCampaignManagerStatusActionSelect.vue';
import { COLUMNS as COLUMNS_CM } from '@/views/private/modules/dashboardSSP/campaignManager/config';

export default {
  name: 'AnalyticsCellOrchestrator',
  components: {
    CampaignManagerStatusActionSelect,
    CampaignManagerActions,
    PlatformPill,
    CurrencyCell,
    PercentageCell,
    NumericCell,
    TextCell,
    DateCell,
  },
  data() {
    return {
      value: null,
      column: null,
      params: null,
      rowData: null,
    };
  },
  computed: {
    isTagCell() {
      return [].some(key => key === this.column);
    },
    isPillCell() {
      return [].some(key => key === this.column);
    },
    isTextCell() {
      // TODO rethink headers array each type (maybe in Column class)
      return [
        'platformName',
        'dealName',
        'date',
        'country',
        openMarketColumns.OPORTUNITY_NAME.value,
        openMarketColumns.ACCOUNT_NAME.value,
        openMarketColumns.PLATFORM_ID.value,
        'ag-Grid-AutoColumn',
        openMarketColumns.DEAL_ID.value,
        openMarketColumns.OFFICE.value,
        // Config for Campaign Manager
        COLUMNS_CM.TYPE.value,
        COLUMNS_CM.STAGE.value,
        COLUMNS_CM.OWNER.value,
        COLUMNS_CM.ACCOUNT_MANAGER.value,
        COLUMNS_CM.CAMPAIGN_ID.value,
        COLUMNS_CM.PRODUCT_ID.value,
        COLUMNS_CM.OFFICE.value,
      ].some(key => key === this.column);
    },
    isNumericCell() {
      // TODO rethink headers array in each type (maybe in Column class)
      return [
        openMarketColumns.REQUESTS.value,
        openMarketColumns.IMPRESSIONS.value,
        openMarketColumns.CLICKS.value,
        openMarketColumns.VIEWS.value,
        openMarketColumns.VIEWABLE_IMPRESSIONS.value,
      ].some(key => key === this.column);
    },
    isPercentageCell() {
      // TODO rethink headers array in each type (maybe in Column class)
      return [openMarketColumns.CTR.value, openMarketColumns.VTR.value, openMarketColumns.VIEWABILITY.value].some(
        key => key === this.column
      );
    },
    isCurrencyCell() {
      // TODO rethink headers array in each type (maybe in Column class)
      return [].some(key => key === this.column);
    },
    isCurrencyAndEurCell() {
      // TODO rethink headers array in each type (maybe in Column class)
      return [
        openMarketColumns.NET_REVENUE_EUR.value,
        openMarketColumns.NET_ECPM_EUR.value,
        // openMarketColumns.NET_ECPM_REQUESTS_EUR.value,
        // openMarketColumns.GROSS_REVENUE_EUR.value,
        // openMarketColumns.GROSS_ECPM_EUR.value,
        // openMarketColumns.GROSS_ECPM_REQUESTS_EUR.value,
      ].some(key => key === this.column);
    },
    isCurrencyAndUsdCell() {
      // TODO rethink headers array in each type (maybe in Column class)
      return [
        openMarketColumns.NET_REVENUE_USD.value,
        openMarketColumns.NET_ECPM_USD.value,
        // openMarketColumns.NET_ECPM_REQUESTS_USD.value,
        // openMarketColumns.GROSS_REVENUE_USD.value,
        // openMarketColumns.GROSS_ECPM_USD.value,
        // openMarketColumns.GROSS_ECPM_REQUESTS_USD.value,
      ].some(key => key === this.column);
    },
    isDateCell() {
      // TODO rethink headers array in each type (maybe in Column class)
      return [
        openMarketColumns.START_DATE.value,
        openMarketColumns.END_DATE.value,
        // Config for Campaign Manager
        COLUMNS_CM.START.value,
        COLUMNS_CM.END.value,
        COLUMNS_CM.CREATED.value,
      ].some(key => key === this.column);
    },
    isFillRate() {
      return [openMarketColumns.FILL_RATE.value].some(key => key === this.column);
    },
    isSelectCampaignManagerActionCell() {
      return [
        // Config for Campaign Manager
        COLUMNS_CM.STATUS.value,
      ].some(key => key === this.column);
    },
    isActionCell() {
      return [
        // Config for Campaign Manager
        COLUMNS_CM.ACTIONS.value,
      ].some(key => key === this.column);
    },
  },
  beforeMount() {
    this.column = this.params.column.colId;
    this.rowData = this.params.data;
    this.value = this.params.value;
  },
};
</script>

<style scoped></style>
