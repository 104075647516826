var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.platform
    ? _c(
        "span",
        {
          staticClass:
            "my-1 px-2 py-1 leading-tight align-middle inline-flex items-center rounded-full",
          class: _vm.bgColor,
        },
        [
          _c(
            "svg",
            {
              staticClass: "h-2 w-2",
              class: _vm.textColor,
              attrs: { fill: "currentColor", viewBox: "0 0 8 8" },
            },
            [_c("circle", { attrs: { cx: "4", cy: "4", r: "3" } })]
          ),
          _c(
            "span",
            {
              staticClass: "ml-2 text-xs font-bold whitespace-no-wrap",
              class: _vm.textColor,
            },
            [_vm._v(" " + _vm._s(_vm.platform.name) + " ")]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }